/**
 * Home container.
 * @module components/theme/NotFound/NotFound
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { withServerErrorCode } from '@plone/volto/helpers/Utils/Utils';

/**
 * Not found function.
 * @function NotFound
 * @returns {string} Markup of the not found page.
 */
const NotFound = () => (
  <Container className="view-wrapper">
    <h1>
      <FormattedMessage
        id="This page does not seem to exist…"
        defaultMessage="This page does not seem to exist…"
      />
    </h1>
     <p className="description">
     Ci scusiamo per l'inconveniente, la pagina cui stai provando ad accedere non esiste a questo indirizzo. 
	 Puoi ripartire dalla <a href="/">homepage</a> o usare il motore di ricerca interno al sito disponibile in alto nella pagina.
    </p>
    <p>
	Se sei sicuro di aver inserito l'indirizzo corretto ma ottieni comunque un errore, contatta l' <a href="https://www.comune.piacenza.it/urp" target="_blank">Ufficio Relazioni con il Pubblico</a>.      
    </p>
    <p>
      <FormattedMessage id="Thank you." defaultMessage="Thank you." />
    </p>
  </Container>
);

export default withServerErrorCode(404)(NotFound);
