import React from 'react';
import { Icon } from '@italia/components/ItaliaTheme';

/*
 * Customization with image
 *
 * If you have a jpg/png, do like this:
 *
 * <figure className="icon">
 *  <img src={logo} alt="" />
 * </figure>
 *
 * Note the icon class.
 */

const LogoFooter = () => <figure className="icon"><img src="/logo.png" alt="" /></figure>;

export default LogoFooter;
